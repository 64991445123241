<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div
      class="relative z-10 flex items-baseline justify-between pt-24 pb-6 border-b border-gray-200">
      <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">Shporta juaj</h1>
    </div>
    <div v-if="form.successful" class="relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
      <div class="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
        <div class="text-green-500">
          <svg class="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        <div class="text-sm font-medium ml-3">Porosia u rezervua.</div>
      </div>
      <div class="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Rezervimi juaj ishte i suksesshëm. Unë do t'ju kontaktoj së shpejti!</div>
      <div class="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-4 text-gray-400 hover:text-gray-800 cursor-pointer">
        <svg @click="form.successful = false" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
      </div>
    </div>
    <div class="flex justify-center my-6">
      <div class="flex flex-col w-full p-8 text-gray-800 bg-white  pin-r pin-y">
        <div class="flex-1">
          <table class="w-full text-sm lg:text-base" cellspacing="0" v-if="items.length">
            <thead>
            <tr class="h-12 uppercase">
              <th class="hidden md:table-cell"></th>
              <th class="text-left">Produkt</th>
              <th class="lg:text-right text-left pl-5 lg:pl-0">
                <span class="lg:hidden" title="Quantity">Qtd</span>
                <span class="hidden lg:inline">Sasia</span>
              </th>
              <th class="hidden text-right md:table-cell">Çmimi</th>
              <th class="text-right">Total</th>
            </tr>
            </thead>
            <tbody>
            <CartItem v-for="item in items" :key="item.id" :item="item" />
            </tbody>
          </table>
           <div v-else>
             <div  class="relative flex justify-center flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
               <div class="tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Shporta juaj është bosh!</div>
             </div>
           </div>
          <hr class="pb-6 mt-6">
          <div class="my-4 mt-6 -mx-2 lg:flex">
            <div class="lg:px-2 lg:w-1/2">
              <div class="p-4 bg-gray-100 rounded-full">
                <h1 class="ml-2 font-bold uppercase">Udhëzim për shitësin</h1>
              </div>
              <div class="p-4">
                <p class="mb-4 italic">Nëse keni disa informacione për shitësin, mund të shkruani
                  ato në fushën më poshtë</p>
                <textarea v-model="form.notes" class="w-full h-24 p-2 bg-gray-100 rounded"></textarea>
              </div>
            </div>
            <div class="lg:px-2 lg:w-1/2">
              <div class="p-4 bg-gray-100 rounded-full">
                <h1 class="ml-2 font-bold uppercase">Detajet e porosisë</h1>
              </div>
              <div class="p-4">
                <div class="flex justify-between border-b">
                  <div
                    class="lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                    Nëntotali
                  </div>
                  <div class="lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                    {{ $filters.currency(subtotal) }}
                  </div>
                </div>
                <div class="flex justify-between pt-4 border-b">
                  <div
                    class="lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                    TVSH
                  </div>
                  <div class="lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                    {{ $filters.currency(tax) }}
                  </div>
                </div>
                <div class="flex justify-between pt-4 border-b">
                  <div
                    class="lg:px-4 lg:py-2 m-2 text-lg lg:text-xl font-bold text-center text-gray-800">
                    Total
                  </div>
                  <div class="lg:px-4 lg:py-2 m-2 lg:text-lg font-bold text-center text-gray-900">
                    {{ $filters.currency(total) }}
                  </div>
                </div>
                <a href="#">
                  <button @click.prevent="createOrder"
                          :disabled="form.busy"
                    class="flex justify-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-gray-800 rounded-full shadow item-center hover:bg-gray-700 focus:shadow-outline focus:outline-none">
                    <svg aria-hidden="true" data-prefix="far" data-icon="credit-card" class="w-8"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                      <path fill="currentColor"
                            d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"/>
                    </svg>
                    <span class="ml-2 mt-5px"> Rezervo Porosinë </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import {mapGetters, mapState} from 'vuex'
import CartItem from '../components/CartItem'
import Form from 'vform'
import axios from 'axios';
import {API_URL} from '@/.env';
export default {
  name: 'Cart',

  components: {
    CartItem
  },
  data() {
    return {
      form: new Form({
        notes: '',
        products : [],
        quantities : [],
      })
    };
  },
  computed: {
    ...mapState('cart', ['items']),
    ...mapGetters('cart', ['subtotal', 'tax', 'total']),
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
  },
  methods: {
    async createOrder () {
      // Submit the form.
      const instance = axios.create({
        baseURL: API_URL,
        headers: {"Authorization" : `Bearer ${Cookies.get('token')}`}
      })

      Form.axios = instance
      const items = JSON.parse(localStorage.getItem('cart'))?.items ?? [];
      if (items.length){
        this.form.products = items.map(item => item.id)
        this.form.quantities = items.map(item => item.quantity)
        const { data } = await this.form.post('/api/order')
        window.scroll(0, window.pageYOffset - 50)
        this.form.reset()
        this.$store.dispatch('cart/emptyCart');
      }
    }
  }
}
</script>

<style></style>
