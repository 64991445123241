<template>
  <tr class="border-t">
    <td class="hidden py-4 md:table-cell">
      <a href="#">
        <img :src="item.photo ? item.photo : require('@/assets/img/thumb.jpg')"
             class="w-20 rounded" alt="Thumbnail">
      </a>
    </td>
    <td>
      <a href="#">
        <p class="mb-2 md:ml-4"> {{ item.name }}</p>
        <form action="" method="POST">
          <button  @click="removeItem(item)" class="text-gray-700 md:ml-4">
            <small>(Fshi produktin)</small>
          </button>
        </form>
      </a>
    </td>
    <td class="justify-center md:justify-end md:flex mt-10">
      <div class="flex">
        <span @click.prevent="changeQuantity(item)" class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-r-0 w-7 h-7 flex items-center justify-center pb-1">-</span>
        <input id="counter"  v-model.number="item.quantity" aria-label="input" class="border dark:text-white border-gray-300 dark:bg-transparent w-14 h-7 text-center" type="text"  />
        <span @click.prevent="addItem(item)" class="focus:outline-none dark:text-white focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer border border-gray-300 border-l-0 w-7 h-7 flex items-center justify-center pb-1">+</span>
      </div>
    </td>
    <td class="hidden text-right md:table-cell">
              <span class="text-sm lg:text-base font-medium">
               {{ $filters.currency(item.sale_price) }}
              </span>
    </td>
    <td class="text-right">
              <span class="text-sm lg:text-base font-medium">
                 {{ $filters.currency(item.sale_price * item.quantity) }}
              </span>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CartItem',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions('cart', ['changeQuantity', 'removeItem','addItem'])
  }
}
</script>

<style></style>
